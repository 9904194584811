//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    inviteService
} from "@/service/inviteService";
import formatDate from "@/core/filter/formatDate";
export default {
    data() {
        return {
            meetingInfo: {
                name: "会议名称",
                userName: "申请人姓名",
                startTime: "2000-01-01 00:00:00",
                duration: 0,
                init: true,
                status: 3 //-1已取消 0未预约 1预定成功 2进行中 3已结束 4取消有损失
            }
        };
    },
    computed: {
        meetingStatus() {
            if(this.meetingInfo.status == -1) {
                return {
                    type: "default",
                    disabled: true,
                    txt: "会议已取消"
                }
            }else if(this.meetingInfo.status < 2) {
                return {
                    type: "default",
                    disabled: true,
                    txt: "会议尚未开始"
                }
            }else if(this.meetingInfo.status == 2) {
                return {
                    type: "primary",
                    disabled: false,
                    txt: "加入会议"
                }
            }else{
                return {
                    type: "default",
                    disabled: true,
                    txt: "会议已结束"
                }
            }
        },
        endTime() {
            let dateStr = this.meetingInfo.startTime.replace(/-/g, "/");
            return formatDate(
                new Date(dateStr).getTime() +
                this.meetingInfo.duration * 60000,
                "yyyy-MM-dd HH:mm:ss"
            )
        }
    },
    created() {
        // this.$store.commit("meetingInvite/SETCONFID", this.$route.query.confId);
        // sessionStorage.setItem("confId", this.$route.query.confId);
        this.getMeetingInfo();
    },
    methods: {
        joinMeeting() {
            this.$router.push({path: "/editPeopleInfo", query: {confId: this.$route.query.confId}});
            
        },
        getMeetingInfo() {
            let params = {
                mettingId: this.$route.query.confId
                // conId: "0e2de6c4-0634-40e3-bb67-0532da5e875f"
            };
            inviteService.QueryMeetingInfo(params).then(res => {
                // setTimeout(()=> this.getMeetingInfo(), 5000);
                if (res.success) {
                    this.meetingInfo = res.content;
                    if(res.content.status == 0 || res.content.status == 1) {
                        setTimeout(() => this.getMeetingInfo(), 60000);
                    }
                }
            });
        }
    }
};
